

.contact-container {
    background:  $dark-grey;   
}

.contact-section {
    margin: 0 $inside-margin;
    padding: 10rem 0;
    

    // &-title {
    //     font-family: $font-secondary;
    //     font-size: 9rem;
    //     padding: 0;
    //     margin: 0;
    //     font-weight: 400;  
    // }

    &-action {
        font-size: 4rem;
        text-align: center;
        font-family: $font-primary;
        color: white;
        

        p {
            margin: 0;
        }
        
    }

    &-links {
        margin: 0 auto;
        max-width: 65rem;
        padding: 2%;
        font-family: $font-secondary;
        
     
        a {
            margin: 0;
            margin-right: 3rem;
            // display: block;
            text-decoration: none;
            color: rgba(255, 255, 255, 0.76);
            font-size: 2rem;
            

            &:hover {
                color: white;
            }

        }

        i{
            // color: #f45e3d; 
            font-size: 1.5rem;
            text-align: center;
            background-color: white;
            color: black;
        }

    }
}

@media (max-width: $screen-xs) {
    .contact-section {
        &-links {
            a{
                display: block;
                font-size: 1.5rem;
            }
            i {
                font-size: 1rem;
                height: 2rem;
                width: 2rem;

                
            }
        }

        &-action p {
            font-size: 3rem;
        }
    }
}
