@import 'Variables.scss';


.about-container {
    // margin-bottom: $outside-margin;
    min-height: 100vh;
    box-sizing: border-box;
    background: rgb(255, 84, 92); 
    // background: #36d1dc;  
    // background: linear-gradient(to right,#36d1dc, #97f3dd); 
    // background: linear-gradient(to right,#36d1dcdd, #97f3dee0);
    
    background-size: cover;
    background-blend-mode: lighten;

    &-inner {
        display: flex;
        margin: $inside-margin;

        // max-width: 120rem;
    }
}

.about-section-title {
    font-family: $font-secondary;
    font-size: 9rem;
    padding: 0;
    margin: 0;
    font-weight: 400;
    // text-transform: uppercase;
}

.about-section-detail {
    display: flex;
    flex: 1;
}

.about-skills {
    // flex-grow: 1;

    width: 40%;
    // margin: 5rem 5rem 5rem 0;
    margin: 0;
    padding: 5rem;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    // display: inline-block;

    // display: flex;
    // justify-content: center;
    // padding-left: 2rem;

    &-title {
        font-size: 4rem;
        padding: 0;
        margin: 0;
        margin-bottom: 1.5rem;
        display: inline-block;
        font-family: $font-primary;
        // text-transform: uppercase;
        // font-weight: 700;
        color: $med-pink-alt;
        // text-shadow: 1px 1px 10px #2ca8b1bd;

        

        color: white;
    -webkit-text-fill-color: rgba(255, 255, 255, 0); 
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
        
    }

    &-list {
        list-style-type: square;

        padding-left: 0;  
        margin-top: 0;

        font-family: $font-secondary;
        // margin-left: 5rem;
        
    }

    &-set {
        // padding: 1rem;
        width: 100%;
        margin-bottom: 3rem;
        position: relative;
    }
}

.about-skill {
    color: white;
    display: inline-block;
    padding: .2rem 0;
    font-size: 2rem;

    // &::before {
    //     content: "\25AA"; 
    //     color: rgba(254, 223, 223, 0.596);
    //     display: inline-block; 
    //     width: 2rem;
    //     margin-left: 1rem
    // }
    
}

.about-story {
    // flex-grow: 2;
    display: inline-block;
    text-align: left;
    line-height: 1.3;
    
    // padding-right: 2.5rem;
    // margin-right: 2.5rem;
    color: white;
    // color: rgb(255, 255, 255);
    font-weight: 200;
    font-size: 2.2rem;

    // width: 60%;

    b{
        font-weight: 400;
    }

    a {
        color: rgba(255, 255, 255, 0.753);
        font-weight: bold;
        
        &:hover {
            color: white;
        }
        
    }

}


.about-section {
    
    padding: 10rem 0;
    border: 5px solid white;
    padding: 5rem;
    width: 60%;
    display: inline-block;
    box-sizing: border-box;
}

.divider-about {
    color: $med-orange; 
}



@media (max-width: $screen-xl) {
    .about-story, .about-skills {
        font-size: 1.75rem;
    }
}

@media (max-width: $screen-lg) {

    .about-container-inner {
        display: block;
        
    }

    .about-section {
        width: 100%;
    }


    .about-story, .about-skills {
        font-size: 2rem;
    }

    .about-skills {
        width: 90%;
        
    }

    .about-skills-list {
        margin-bottom: 1rem;
    }


  }

  @media (max-width: $screen-xs) {

    .about-container-inner {
        margin: 2rem;
    }

    .about-section {
        margin: 0;
        padding: 0 0 3rem 0;
        border: none;
        border-bottom: 5px dotted rgba(255, 255, 255, 0.24);
        width: 100%;
    
        &-title {
            font-size: 5rem;
        }
    }


    .about-story, .about-skills {
        font-size: 2rem;
    }

    .about-story {
        margin-right: 0rem;
        font-size: 90%;
    }

    .about-skills {
        margin: 0;
        padding: 3rem 0 0 0;
        width: 100%;
        

        &-set{
            padding: 0;
            
        }
        
    }

    .about-skills-title {
        font-size: 2.5rem;
        margin-bottom: 1rem;
        
    }

    .about-skills-list {
        margin-bottom: 1rem;
        .about-skill {
            font-size: 70%;
        }
    }


  }

//   @media (min-width: 120rem) {
//     .about-container-inner {
//         margin: 5rem auto;
//         max-width: 120rem;
//     }
// }