
.nav-container {
    display: flex;
    justify-content: space-between;
    
}

.nav-logo-container {
    display: inline-block;
    padding: 1rem;
}

.logo {
    border: 2px solid #ff9966;
    display: inline-block;
    padding: .5rem;
    font-weight: 700;
}

.nav-section-links {
    text-transform: uppercase;
    // display: inline-block;
    // padding: .5rem;
    // margin-right: 2rem;
    font-family: $font-secondary;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: nowrap;

}

.nav-section {
    padding: 1rem 0rem;
    display: inline-block;
    margin: 0;
    font-size: 4rem;
    font-weight: 700;
    
    
    a {
        color: black;
        text-decoration: none;
        font-weight: 400;
        width: 100%;
        // transition: .2s all ease;


        &:hover {
            border-bottom: 5px dotted rgba(0, 0, 0, 0.082);

        }
    }
    
}

@media (max-width: $screen-xl) {
    .nav-section a {
   font-size: 3rem;
    }
}

@media (max-width: $screen-lg) {
    .nav-section a {
   font-size: 2rem;
    }

    .nav-section .header-divider {
        font-size: 2rem;
        // padding: 1rem 0rem;
    }
}