@import 'About.scss';
@import 'Contact.scss';
@import 'Header.scss';
@import 'Project.scss';
@import 'Projects.scss';
@import 'Variables.scss';



.App {
  // margin: $outside-margin;
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
  
  box-sizing: border-box;
  font-family: $font-primary;
  
  // width: 100%;
  
  font-weight: 300;
  font-size: 1.6rem;
  transition: all 1s ease;
  -webkit-transition:  all 1s ease;
  -ms-transition: all 1s ease;
  background-blend-mode: darken;
  
  // background: #ff5e62; 
    //             url(workStation.jpg);

    //             background: linear-gradient(to right, #ff5e62, #ff9966),
    //             url(abstract.jpg);  
    // background-size: cover;
    // background-blend-mode: multiply; 
                
    
    // background: -webkit-linear-gradient(#ff5e62, #ff9966), 
    //             url(geo-pattern.png); 
    // background: linear-gradient(#ff5e62, #ff9966),
    //             url(geo-pattern.png);  
    
    position: relative;
    // z-index: -11;

    // &-gradient {
    //   background: -webkit-linear-gradient(#14141400, #fefefe); 
    //   background: -linear-gradient(#14141400, #fafafa); 
    //   position: fixed;
    //   top: 0;
    //   left: 0;
    //   bottom: 0;
    //   right: 0;
    //   z-index: -10;
    // }
}


.bk-green-gradient {
  background: #303496;
// background: #dadbfc;


background-size: cover;
position: relative;

}

.bk-purple-gradient {
  // background: #ECE37C;
  // background: #303496;
  background: #175bbf;
  
background-size: cover;
position: relative;

}

.bk-blue-gradient {
  background: #35accf;
  // background: rgb(0, 173, 189); 
  // background: #303496;
  
  background-size: cover;
  position: relative;

}

.bk-teal-gradient {
  // background: #92E6D8; 
  // background: rgb(2, 141, 187); 
  background: #3684a4;
  
  background-size: cover;
  position: relative;

}

.bk-green2-gradient {
  background: #92E6D8; 


  background-size: cover;
  position: relative;


}


// .App-header {
//   background-color: #282c34;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
// align-items: center;
// justify-content: center;
// font-size: calc(10px + 2vmin);
//   color: white;
// }

// .App-link {
//   color: #61dafb;
// }

// @keyframes App-logo-spin {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }
