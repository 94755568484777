html {
  font-size: 62.5%;
  box-sizing: border-box;

}

*{
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  
  

  // background: linear-gradient(to right, #ff5e62, #ff9966),
  //               url(Styling/funky-lines.png);  

  //   background-blend-mode: overlay; 
  //   background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


