

.project-container {
    display: flex;
    margin: 10rem 0;
    position: relative;
    padding: 5rem;
    border: .5rem solid black;
    background-color: white;
}

.project-description {
    line-height: 1.4;
}

.project-image {
    width: 90%;
    border: 1px solid rgb(228, 228, 228);
    margin: 1rem 0rem;
    z-index: 3;
    position:relative; 
    filter: opacity(.9) grayscale(100%);
    // filter: invert(0.2) grayscale(80%) hue-rotate(330deg) brightness(1.2);
    // -webkit-filter: invert(0.2) hue-rotate(330deg) brightness(1.2) grayscale(80%);
    // display: inline-block
    transition: .5s all ease-out;

    &:hover {
        filter: none; 
    }
}

.project-images {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    
}

.bg-project, .dragon-project, .prod-project, .job-project {
    color: $dark-grey;
    h2 {
        color: $med-pink;
    }
    
    h3 {
        color: $med-pink2;
    }

    button {
        background: white;
        border: 2px solid $med-pink; 
        // box-shadow: 3px 3px 0px rgb(0, 0, 0);
        width: 45%;
        max-width: 200px;
        
       
        a {
            text-decoration: none;
            color: $med-pink;
            
            font-weight: 700;
            font-size: 1.75rem;
        } 

        &:hover {
            // background: $med-pink;
            border: 2px solid $light-teal; 
            background: $bright-pink;  
            
            a {
                // color: white;
                color: white;
            }
            // background: linear-gradient(to right, #fc6b63, #ffa382);
            // background: linear-gradient(to right, #fc6b63, #ffa382);
        }
    }

    .divider {
        color: $med-orange;  
    }

    .project-role b, .project-context b{
        font-family: $font-secondary;
        font-weight: 400;
        text-transform: uppercase;
        margin-right: .5rem;
    }

}

// .bg-project {

//     .project-number-outline {
//     color: $med-pink;
//     -webkit-text-fill-color: rgba(255, 255, 255, 0); 
//     -webkit-text-stroke-width: 3px;
//     -webkit-text-stroke-color: $med-pink;
//     }
// }

.bg-project, .prod-project {
    margin-left: 15rem;

    .project-number-outline {
        // position: absolute;
            left: -15rem;
    }
}

.dragon-project, .job-project {
    margin-right: 15rem;
    .project-section-left{
        order: 2;
    } 

    .project-section-right {
        order: 1;

        padding-right: 5rem;
        padding-left: 0rem;
    }

    .project-number {

        &-outline{
            position: absolute;
            right: -15rem;
        }
    }

}

h2, h3 {
    padding: 0;
    margin: 0;
}

h2 {
    font-family: $font-secondary;
    font-weight: 400;
    font-size: 4.5rem;
}

.project-section-left, .project-section-right {
    display: inline-block;
    // width: 50%;   
     
}

.project-section-left {
    position: relative;
    width: 40%;
}


.project-section-right {
    // padding: 0rem 5rem;
    padding-left: 5rem;
    padding-right: 0rem;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
}

.project-demo-btn {
    padding: 1rem;
    background: none;
    text-transform: uppercase;
    width: 16rem;
    font-size: 1.5rem;
    font-family: $font-primary;
    margin-right: 1rem;
    transition: .35s all ease;
}

.project-number {
    font-size: 25rem;
    font-family: $font-secondary;
    margin: 0;
    // text-shadow: 3px 3px 0px rgb(206, 191, 191);
    color: $med-pink;
    // background: linear-gradient(to bottom, #fc6b63, rgb(255, 57, 77));
    // background: -webkit-linear-gradient(to bottom, #fc6b63, rgb(255, 57, 77));
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;

    &-outline {
        z-index: 5;
        position: absolute;
        top: -5rem;
    }

}


.tech-skill {
    // font-weight: 700;
    font-size: 2rem;
    font-family: $font-secondary;
}

.divider {
    padding: 0rem 1rem;
    display: inline-block;
}

.project-link-btns {
    margin-top: 2rem;
    
}

// @media (max-width: $screen-xl) {
//     .bg-project, .prod-project {
//         margin-left: 8rem;
    
//         .project-number-outline {
            
//                 left: -10rem;
//         }
//     }
// }

@media (max-width: $screen-xl) {
    .project-section-left {
        width: 50%;
    }
    
    .project-section-right {
        width: 50%;
    }
}

@media (max-width: $screen-lg) {
    .project-container {
        display: block;
        
        .project-section-right {
            padding-left: 0rem;
            display: block;
            width: 100%;
        }

        .project-section-left {
            // display: block;

            width: 100%;
            order: 2;
        }

        .project-images {
            display: block;
        }

        .project-image {
            width: 50%;
            padding: .5rem;
            padding-bottom: 3rem;
            box-sizing: border-box;
            // margin: .5rem auto;
            z-index: 3;
            // display: block;
            border: 0px;

            &-main {
                z-index: 5;
            }

            &-secondary {
                z-index: 1;
                // display: none;
            }
        }

        
    } 

    .project-number {
        font-size: 18rem;
    }

    .bg-project, .prod-project {
        margin-left: 8rem;
    
        .project-number-outline {
                left: -10rem;
        }
    }

    

    .dragon-project, .job-project {
        margin-right: 8rem;
    
        .project-number-outline {
                right: -10rem;
        } 
    }

    .bg-project, .prod-project, .dragon-project, .job-project {
        button {
            max-width: 200px;
        }
    }

}

@media (max-width: $screen-m) {
    .bg-project, .prod-project {
        margin-left: 0rem;
    
        .project-number-outline {
            display: none;
        }
    }

    .dragon-project, .job-project {
        margin-right: 0rem;
    
        .project-number-outline {
                display: none;
        } 
    }
}

@media (max-width: $screen-xs) {
    h2 {
        font-size: 3rem;
    }

    .tech-skill {
        font-size: 1.5rem;
    }

    .project-demo-btn {
        // width: 80%;
        height: 4rem;
        font-size: .9rem;
        line-height: 1rem;
    }
    
    .project-container {
    //   display: block;  
      font-size: 80%;  
      margin: 2rem 0;
      border: 3px solid black;

        .project-images {
            display: block;
            flex-direction: row;
            justify-content: center;
            height: 50%;
            position: relative;
            margin-bottom: 2rem;
            
        }

        .project-demo-btn {
            width: 45%;
            margin-right: 1rem;

            a {
                font-size: 1.5rem;
            }
        }

        .project-number {
            display: none;
        }

        .project-image {
            
            // margin: .5rem auto;
            // z-index: 3;
            display: block;
            padding-bottom: 0rem;
            width: 100%;
            
            // border: 0px;

            &-main {
                z-index: 5;
            }

            &-secondary {
                // z-index: 1;
                display: none;
            }
        }


    }
 
}


