$font-primary: 'Raleway', sans-serif;
$font-secondary: 'Oswald', sans-serif;

// color palette

$light-pink: #ffdad8;
$light-orange: #ffc581;
$med-green: #c1d045;
$med-green2: #b8c5413b;
$light-green: #dff048;
$lightest-green: #f7fade;
$light-blue: #91e4fb;
$light-magenta: #ca87af;
$lightest-magenta: #fdc5e7;

$med-pink2: #fc6b63;
// $med-pink: #CA1551;
// $med-pink: #037367;
// $med-pink: #F71735;
$med-pink: #F7444E;
$med-pink-alt: #ffcdcb;

$med-orange: #feb5a4;
$dark-grey: rgb(34, 34, 34);
// $bright-pink: #E23949;
$bright-pink: #87e1d8;

$dark-blue: #140F2D;
$teal: #218380;
$yellow: #Bea42e;
$purple: #4B1D3F;
$blue: #3F88C5;
$light-teal: #87e1d8;

// ED4675
// 65AEBB


$outside-margin: 2rem;
$inside-margin: 5rem;


//breakpoints
// @media all and (max-width: 1199px) { ... } 
// @media all and (max-width: 991px) { ... } 
// @media all and (max-width: 768px) { ... } 
// @media all and (max-width: 575px) { ... }

$screen-xs: 575px;
$screen-m: 768px;
$screen-lg: 991px;
$screen-xl: 1199px;
