@import 'Navigation.scss';
@import 'Variables.scss';


.header-container {
    
    background: white;  
    background: linear-gradient(to right, rgb(184, 202, 197), rgb(184, 202, 197));
    background: linear-gradient(to right, rgb(184, 202, 197), rgb(184, 202, 197)),
    url('worn_dots.png');
    
    background-blend-mode: overlay;

    // background: url('deco2.png');


    // min-height: 100vh;
    height: 100vh;
    box-sizing: border-box;
    
    display: flex;
    flex-direction: column;
    padding: 5rem;
}

// .name {
//     color: #d6514a;
// }

.header-description {
    width: 100%;
    border-top: 5px solid black;
    // border-bottom: 5px solid black;
    
    padding-top: 5rem;
    margin-top: 5rem;
    position: relative;
    text-align: justify;
    
    &-container {
        // margin: $inside-margin;

        border: 5px solid black;
        background: white;
        min-height: 100%;
        box-sizing: inherit;
        // position: relative;
    }

    &-links {
        width: 80rem;
        margin: 0rem auto;
        // padding:10rem 0;
        box-sizing: inherit;

        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;

        // position: absolute;
        // top: 0%;
        // left: 50%;
        // transform: translate(-50%, -50%);
    }
}

.header-hello {
    color: black;
    background: white;
    // position: absolute;
    // top: -5.5rem;
    // left: 25rem;

    position: absolute;
        top: 0%;
        left: 50%;
        transform: translate(-50%, -50%);

    font-size: 9rem;
    margin: 0;
    padding: 0 2rem;
    
}

.header-divider {
    color: $med-orange;
    font-size: 5rem;
    // padding: 1rem 0rem;
    
}

.header-intro {
    font-family: $font-secondary;

    font-size: 11rem;
    display: flex;
    justify-content: space-between;
    // width: 100%;

    padding: 0;
    margin: 0;
    font-weight: 400;
    
    
    text-transform: uppercase;
    // color: $light-teal;
    color: $med-pink;

    // background: linear-gradient(to bottom, $light-teal, $teal);
    // background: -webkit-linear-gradient(to bottom, $light-teal, $teal);
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;

    &-tagline {
        // color: rgba(105, 105, 105, 0.507);
        color: rgb(70, 70, 70);
        font-size: 3rem;
        font-weight: 300;
        padding-bottom: 4rem;
        margin: 0;
        line-height: 1.5;
        text-align: center;
        letter-spacing: .3rem;
        
    }
}



.nav-section-links {
    border-top: 5px solid black;
    
}

.fa-envelope, .fa-github, .fa-linkedin-in {
    font-size: 1.5rem;
    padding: .5rem;
    margin: .75rem;
    background-color: black;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 100rem;

    &:hover {
        background-color: $bright-pink; 
    }
    
}

.contact-links {
    position: absolute;
    // top: 40vh;
    left: .5rem;
    top: .5rem;
    text-align: center;

    a {
        color: white;
        display: block;
    }

    
}

@media (max-width: $screen-xl) {
    .header-description {
        
        &-links {
            width: 80%;
        }
    }

    .header-intro {
        font-size: 10.5rem; 

        &-tagline {
            font-size: 2.75rem;
        }
    }

    // .header-divider {
    //     font-size: 3rem;
    //     padding: 1rem 0rem;
    // }

    
}

@media (max-width: $screen-lg) {
    
    .header-intro {
        font-size: 8rem;
        // &-tagline {
        //     font-size: 3.5rem;
        // }
    }

    .nav-section-links {
        .header-divider, .nav-section {
            font-size: 2.5rem;
            padding: .3rem;
        }
    }
}

@media (max-width: $screen-m) {
    
    .header-intro {
        font-size: 5.6rem;

        

        &-tagline {
            font-size: 2.5rem;
        }
    }
    .header-hello {
        font-size: 6rem;
    } 

    // .header-description-links {
    //     margin-top: 7rem;
    //     margin-bottom: 4rem;
    // }

    
}

@media (max-width: $screen-xs) {

    .contact-links {
        right: .5rem;
        top: .5rem;
        a {
            display: inline-block;
        }
    }

    .header-description {
        border-top: 1px solid black;
        padding: 1.5rem 0;
        margin-top: 1.5rem;

        &-container {
            padding: 0;
            // margin: 10rem 2rem;
            text-align: center;
            border: 3px solid black;
            // min-height: 50%;

            .header-intro {
                font-size: 3.5rem;
        
                &-tagline {
                    font-size: 1.5rem;
                    padding-bottom: 3rem;
                    
                }
            }

        }

        &-links {
            width: 90%;
            // padding-top: 15%;
            // padding-bottom: 4rem;
            height: 100%;
            
            // margin-bottom: 5rem;
            
        }
    }

    .header-container {
        min-height: 50vh;

        padding: 8rem 2rem;
    }

    .header-hello{
        font-size: 4rem;
    }
    

    .nav-section {
        &-links {
            margin-right: 0;
            box-sizing: border-box;
            padding: 0;
            border-top: 1px solid black;

            .nav-section a {
                font-size: 80%;
            }
            .header-divider, .nav-section {
                font-size: 1.5rem;
                padding: .3rem;
            }
        }
    }

}

// @media (max-width: $screen-m) {

//     &-container {
//         .header-intro {

//             font-size: 6rem;
    
//             &-tagline {
//                 font-size: 4rem;
//             }
//         }
//     }
// }



