

.projects-container {

    min-height: 100vh;
    background-size: cover;
    background-blend-mode: overlay;
    

    background: white;  
    background: linear-gradient(to right, rgb(147, 161, 158), #C6D8D3);
    background: linear-gradient(to right, rgb(147, 161, 158), #C6D8D3),
    url('triangles.png');
    
}

.projects-section {
    margin: 0 $inside-margin;
    padding: 10rem 0;
    

    

    &-title {
        font-family: $font-secondary;
        font-size: 9rem;
        padding: 0;
        margin: 0;
        font-weight: 400;
        color: black;
        display: inline-block;
        // text-transform: uppercase;
        
    }

    .addtl-projects {
        text-align: center;
        font-family: $font-secondary;
        font-size: 3rem;
    }
}


.line-horiz-projects {
    width: 20rem;
    display: inline-block;
    // margin-left: 2rem;
    background-color: black;
    border: none;
    height: 5px;
    margin: 3rem 0rem 3rem 2rem;

}

a.github-link {
    font-weight: bold;
    color: black;
    text-decoration: none;
    border-bottom: 2px solid $med-pink;
    transition: .25s all ease;

    &:hover {
        color: $med-pink;
    }
}

@media (max-width: $screen-xs) {
    .projects-section {
        margin: 0;
        padding: 2rem;

        &-title {
            font-size: 5rem;
        }

        .addtl-projects {
            font-size: 2rem;
        }
    }
    
    .projects-container {
        display: flex;
        
        flex-direction: column;
        justify-content: space-between;
        
        .bg-project, .dragon-project, .prod-project, .job-project {
            padding: 0rem;
        }
    }

    

    .project-image.project-image-main {
        padding: 0;
        margin: 0;
    }

    .project-section-right {
        box-sizing: border-box;
        padding: 2rem !important;
    }

    .project-section-left, .project-section-right {
        width: 100%;

        .project-images {
            margin-bottom: 0;
        }
    }
    // .project-section-right {
    //     margin-bottom: 10rem;
    // }  
    
    .line-horiz-projects {
        height: 3px;
        width: 10rem;
        margin: 1.5rem;
    }
}